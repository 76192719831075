<template>
  <form v-if="!submitted" @submit.prevent="handleSubmit">
    <div class="text-center">
      <h4 class="">Forgot Password</h4>
      <router-link to="/login">&larr; Sign In</router-link>
    </div>
    <TextInput
      required
      v-model="email"
      :validator="$v.email"
      type="email"
      label="Email"
      name="username"
      class="text-left my-3"
      id="username"
      placeholder="user@email.com"
    />
    <div class="text-center">
      <button type="submit" :disabled="isLoading" class="btn btn-primary">Submit</button>
    </div>
  </form>
  <div v-else class="text-center">
    <h3>Thank you!</h3>
    <p>
      If your email was found in our system you will receive instructions to reset your password.
    </p>
    <div class="links mt-4">
      <router-link class="mx-3 btn btn-primary mx-1" :to="{ name: 'Login' }">Login</router-link>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import UsersService from "@/services/users";
import TextInput from "@/components/common/TextInput.vue";

export default {
  name: "Forgot",
  components: {
    TextInput
  },
  data() {
    return {
      email: "",
      submitted: false,
      isLoading: false
    };
  },
  validations() {
    return {
      email: {
        required
      }
    };
  },
  methods: {
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        this.isLoading = true;
        await UsersService.forgotPassword({ email: this.email });
        this.submitted = true;
      } catch (error) {
        console.log("forgot password failed", error);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
